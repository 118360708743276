"use client"; // This makes the component a Client Component
import React,{useEffect} from 'react'
import { useRouter,usePathname } from 'next/navigation';


const generateSchema = (pathname, baseUrl) => {
    switch (pathname) {
      case '/home':
        return {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Ashlok",
          "url": baseUrl,
          "logo": `${baseUrl}ashlok_logo.svg`,
          "contactPoint" : 
             { "@type" : "ContactPoint",
            "telephone" : "+91 - 44 2625 4757",
                "contactType" : "Customer Service",
             },
             "sameAs": [
              "https://www.facebook.com/profile.php?id=100068589324482",
              "https://www.linkedin.com/company/8507004/admin/inbox/thread/2-YjM4ZmE3ZjYtYmJhMy00NjRkLTk0MzItNWQxNDYyNjViZTY2XzAxMA==",
               "https://www.instagram.com/ashlok_safe_earthing"
            ]
        };
      case '/product':
        return {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": "Earthing Rod",
         "description": "High-quality copper-bonded earthing rod for lightning protection.",
            "sku": "12345",
            "offers": {
            "@type": "Offer",
            "url": "https://www.ashlok.com/ashlok-copper-bonded-rods",
            "image":[`${baseUrl}banner.jpg`,`${baseUrl}banner_5.jpg`],
            "priceCurrency": "INR",
            "price": "1000",
            "availability": "https://schema.org/InStock"
            },
            "review": 
            {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": 5,
                "bestRating": 5
              },
              "author": {
                "@type": "Person",
                "name": "Arjun Sai"
              }
            }
                    
       };
    
    case '/ourheritage':
        return {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Ashlok",
            "description": "Leading manufacturer of earthing and lightning protection products.",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "123 Ashlok Street",
            "addressLocality": "Chennai",
            "addressRegion": "TN",
            "postalCode": "600001",
            "addressCountry": "IN"
            },
            "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-123-456-7890",
            "contactType": "Customer Service"
            }

        };
      case '/ourservices':
        return {
          "@context": "https://schema.org",
          "@type": "Service",
          "serviceType": "Earthing Protection System",
          "provider": {
                "@type": "Organization",
            "name": "Ashlok"
            },
            "areaServed": {
                "@type": "Place",
                "name": "India"
            }
        };
        case '/contact':
        return {
          "@context": "https://schema.org",
          "@type" : "Organization",
          "url": baseUrl,
          "contactPoint" : [
             { "@type" : "ContactPoint",
            "telephone" : "+91 - 44 2625 4757",
                "contactType" : "customer service",
           
                "areaServed": {
                "@type": "Place",
                "name": "India"
                }
            }],
        };
      default:
        return {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Ashlok",
            "url": baseUrl,
            "logo": `${baseUrl}/logo.png`,
          };
    }
  };

const HeaderInner =()=> {
    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
    //console.log("base url="+baseUrl); // Check if the value is correct
    const pathname=usePathname();
    //const canonicalUrl = `${baseUrl}${ pathname}`;
    const canonicalUrl = `${baseUrl}`;
    const isHomePage = pathname === '/home'; 
    console.log(`paths is ${ pathname}`);

    // useEffect(() => {
    //     console.log("Adding schema...");
        
    
    //     if (schema) {
    //         console.log("schema entere--------");
    //       const script = document.createElement('script');
    //       script.type = 'application/ld+json';
    //       script.textContent = JSON.stringify(schema);
    //       document.head.appendChild(script);
    //             console.log("schemea added")
    //       return () => {
    //         // Cleanup to avoid duplicate script tags
    //         document.head.removeChild(script);
    //       };
    //     }
    //   }, [pathname, baseUrl]);

    useEffect(() => {
        console.log("Adding schema...");
        if (schema) {
            console.log("withon schema...");
            <script type="application/ld+json">
            dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
          </script>
        }

    }, [pathname, baseUrl]);

    
      const schema = generateSchema(pathname, baseUrl);
  return (
    <>

        <link rel="canonical" href={canonicalUrl} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
                schema
            }),
          }}
        />
    </>
  )
}

export default HeaderInner